@import '../../scss/variable.scss';

.button {
  height: 32px;
  width: 124px;
  outline: none ;
  box-shadow: none ;
  outline-style: none ;
  background: $button-primary;
  border-radius: 3.15px;
  border: none;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  color: $color-text;
  &:hover{
    background: $button-primary-hover;
  }
  &:disabled{
    opacity: 0.5;
    pointer-events: none;
  }
}

.secondary {
  color: $color-secondary;
  background: $button-secondary;
  &:hover{
    background: $button-secondary-hover;
  }
}
@import '../../scss/variable.scss';

.dropdownContainer {
  border-radius: 5px;
  background: $background-select;
  font-weight: 500 !important;
  font-size: 13px;
}
  
.dropdown__control {
  min-height: 35px !important;
  max-height: 35px !important;
  border-radius: 5px !important;
  background: $background-select;
  color: $color-text;
  border-width: 1px !important;
  border: 1px solid  $color-text;
  .dropdown__single-value{
    color: $color-text !important;
  }
}

.dropdown__control--menu-is-open,
.dropdown__control--is-focused {
  background: $color-text !important;
  color: $background-select !important;
  .dropdown__single-value{
    color: $background-select !important;

  }
}

.dropdown__menu {
  min-width: 250px;
  font-weight: 500;
  margin-top: 5px !important;
  padding: 0px !important;
  border-radius: 5px !important;
  overflow: hidden;
  padding: 0px !important;
  background: $background-select;
  border: 1px solid $color-text;
  opacity: 1 !important;
  .dropdown__option:hover {
    background: $color-text !important;
    color: $background-select !important;
  }
}


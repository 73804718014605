@import '../../scss/variable.scss';

.topbar{
  height: 40px;
  border-radius: 4px;
  background-color:  $background-highlighting;
  display: flex;
  margin: 0 18px;
  align-items: center;
  color: $color-text;
  padding: 0 18px;
  font-size: 15.6px;
  font-weight: bold;
  letter-spacing: 2.01px;
  line-height: 23px;
  text-align: justify;
  text-transform: uppercase;
}
@import './variable.scss';
@import '../static/font/stylesheet.css';
@import './BitaCalendarStyle.scss';

.App {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  text-align: center;
  letter-spacing: 1px;
}

.searchInput{
  width: 200px;
  height: 32px;
  letter-spacing: 1.40px;
  color: #fff;
  background-color: transparent;
  background-image: url('../static/icon/search.svg');
  background-repeat: no-repeat;
  background-position: 0px 10px;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  padding-left: 20px;
  font-size: 12px;
  &::placeholder {
    color: #fff !important;
  }
}

input{
  color: $color-text;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #c7c7c7;
  padding: 8px;
  padding-left: 0;
  &::placeholder {
    color: #fff !important;
    opacity: 0.5;
  }
}
textarea{
  width: 100%;
  color: $color-text;
  background: #2A2D31;
  padding: 10px;
  border: none;
}


//style toast 
.Toastify__toast {
  background: linear-gradient(270deg, #0234b3ef 10%, #2962ff 100%) !important;
  color: #ffffff !important;
  font-weight: bold !important;
  z-index: 999992399;
}

.Toastify__toast-container {
  z-index: 99999999 !important;
}

.Toastify__close-button {
  color: #ffffff !important;
  opacity: 1 !important;
}

.Toastify__toast-icon{
  display: none !important;
}

//style scroll bar 
.rcs-custom-scroll .rcs-inner-handle{
  background-color: #000000
}

//style input ratio 
[type='radio']:checked,
[type='radio']:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type='radio']:checked + label,
[type='radio']:not(:checked) + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  color: white;
  margin-right: 15px;
}
[type='radio']:checked + label:before,
[type='radio']:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: transparent;
}

[type='radio']:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 17px;
  height: 17px;
  border: 1px solid #2962ff;
  border-radius: 100%;
  background: transparent;
}
[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #2962ff;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
[type='radio']:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type='radio']:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

//style disabled
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
@import '../../scss/variable.scss';

.navbar{
    background: $background-main;
    align-items: center;
    height: 40px;
    padding: 0 18px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    overflow: hidden;
    .item{
      margin-left: 5px;
      font-weight: 400;
      cursor: pointer;
      &:hover{
        font-weight: 600;
      }
    }
}
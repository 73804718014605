@import '../../scss/variable.scss';

.card {
  min-width: 250px;
  min-height: 150px;
  transition-duration: 1s;
  color: $color-text;
  letter-spacing: 1px;
  box-shadow: $shadow-main;
  background: $background-card;
  border-radius: 8px;
  padding: 13px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  .title{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 25px;
    margin-top: 5px;
  }
  .text{
    width: 70%;
    font-size: 12px;
  }
  img {
    position: absolute;
    right: -4%;
    bottom: -4%;
    height: 55%;
    transition-duration: 1s;
  }
  &:hover {
    background: $background-gradient-highlighting;
    transform: scale(1.08);
  
    img, svg {
      transform: scale(1.2);
    }
  }
}


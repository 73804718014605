@import '../../scss/variable.scss';

.user-management-page{
  color: $color-text;
  background-color: $background-secondary;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 10px;
  text-align: left;
  .content-widget{
    background-color: $background-widget;
    border-radius: 10px;
    padding: 18px;
    text-align: left;
    .sub-widget{
      background-color:$background-subwidget;
      border-radius: 10px;
      padding: 18px;
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      > div{
        margin-right: 10px;
      }
    }
    .content-list{
      padding: 5px 0px;
      .header-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .title{
          color: $color-secondary;
          font-size: 18px;
          font-weight: 500;
          display: flex;
          align-items: center;
          svg {
            margin-right: 10px;
          }
        }
      }
      .content-table{
        font-size: 15px;
        .table{
          margin-right: 15px;
          height: 370px;
        }
        .head-table{
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 10px 18px;
          font-size: 10px;
          > div:nth-child(1){
            width: 150px;
          }
          >div:nth-child(2){
            width: 80px;
            margin-left: 20px;
          }
          >div:nth-child(3){
            width: 200px;
          }
          > div:last-child{
            width: 180px;
          }
          svg{
            margin-left: 5px;
          }
          .desc{
            transform: rotate(180deg);
          }
        }
        .body-table{
          .row{
            background-color:$background-subwidget;
            border-radius: 10px;
            padding: 18px;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;
            > div:nth-child(1){
              width: 150px;
            }
            >div:nth-child(2){
              width: 100px;
              text-transform: capitalize;
            }
            >div:nth-child(3){
              width: 200px;
              font-size: 12px;
            }
            > div:last-child{
              width: 180px;
            }
            .content-button{
              display: flex;
              button:nth-child(1){
                margin-right: 10px;
              }
            }
          }
        }
      }
    }
    .content-user-organization-section{
      background-color:$background-subwidget;
      border-radius: 10px;
      padding: 18px;
      text-align: left;
      .header-company{
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        margin-bottom: 25px;
        .name-company{
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 2.42308px;
          text-transform: uppercase;
        }
        .expiration-date{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
        .content-buttons{
          min-width: 300px;
        }
        button{
          width: 90px;
          margin-left: 8px;
        }
        .plan{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        }
      }
      .content-company-data{
        > div:nth-child(1){
          font-weight: 500;
          font-size: 18px;
          letter-spacing: 2.42px;
          padding: 10px 0px;
          border-bottom: 1px solid #FFFFFF;
          margin-bottom: 25px;
        }
        .form-company{
          .row{
            margin-bottom: 15px;
            .label{
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 2.42px;
              color: $color-secondary;
            }
            input{
              width: 100%;
              font-weight: 400;
    
            }
            textarea{
              margin-top: 10px;
            }
            .characters-left{
              font-size: 10px;
              text-align: right;
            }
            .custom-branding{
              width: 100%;
              display: flex;
              justify-content: space-between;
              flex-wrap: wrap;
              padding-top: 10px;
              
              .content-colors-palette{
                width: 50%;
                min-width: 300px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                > div:nth-child(1){
                  font-size: 15px;
                  font-weight: 500;
                  margin-bottom: 5px;
                  width: 100%;
                }
                > div:nth-child(2){
                  width: 100%;
                  font-size: 12px;
                }
              }
              .branding-palette-container{
                width: 230px;
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                .palette-option-container{
                  width: 60px;
                  height: 70px;
                  margin-right: 15px;
                  margin-bottom: 10px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: center;
                  .paletteColor {
                    width: 50px;
                    height: 45px ;
                    border-radius: 2px;
                    display: flex;
                    justify-content: flex-end;
                    cursor: pointer;
                    .colorEditIcon {
                      height: 22px;
                      width: 24px;
                      margin-right: 1px;
                      align-self: center;
                    }
                    .dot{
                      background-color: #fff;
                      height: 10px;
                      width: 10px;
                      align-self: flex-end;
                      border-radius: 10px 2px 2px 2px;
                    }
                  }
                  span{
                    font-weight: 400;
                    font-size: 11px;
                    line-height: 13px;
                    letter-spacing: 2.42px;
                    text-align: center;
                    margin-top: 5px;
                  }
                }
              }
              .content-logo-preview{
                width: 50%;
                min-width: 200px;
                > div:nth-child(1){
                  font-size: 15px;
                  font-weight: 500;
                  margin-bottom: 5px;
                }
                > div:nth-child(2){
                  font-size: 12px;
                }
                .content-preview{
                  display: flex;
                  align-items: flex-end;
                  margin-top: 15px;
                  .preview{
                    width: 154px;
                    height: 71px;
                    margin-right: 20px;
                    background: #2A2D31;
                    img{
                      width: 154px;
                      height: 71px;
                    }
                  }
                }
              }

            }
            .apply-change-button{
              margin-left: calc(100% - 140px);
            }
          }
        }
      }
    }
    .user-list-content{
      .content-table-user{
        height: 290px;
      }
      .label{
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 2.42px;
        color: $color-secondary;
        margin-bottom: 10px;
      }
      table{
        width: calc(100% - 15px);
        border-collapse: collapse;
        thead{
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          tr th{
            padding-bottom: 5px;
          }
        }
        tbody{
          background: #2A2D31;
          font-size: 12px;
          tr{
            border-bottom: 1px solid #FFFFFF;
            td{
              padding: 10px;
              button{
                margin-left: 10px;
              }
            }
          }
          .table-buttons{
            display: flex;
            align-items: center;
          }
        }
      }
      .content-button{
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
      }
    }
    .save-button-bottom{
      margin-left: calc(100% - 132px);
      position: relative;
      top: -30px;
    }
    .plan-button{
      width: 80px;
      padding: 2px;
      text-align: center;
      border-radius: 31px;
      background: linear-gradient(180deg, #D9DDE4 0%, #EFF1F4 106.8%);
      color: #3B3F44;
      margin: 0px 5px;
    }
    .trial{
      background: linear-gradient(180deg, #FA5400 0%, #FE6D24 0.01%, #FF2929 100%);
      color: $color-text;
    }
    .core{
      background: linear-gradient(180deg, #245DF8 0%, #0039CB 106.8%);
      color: $color-text;
    }
    .premium{
      background: linear-gradient(180deg, #4B4F56 0%, #313336 106.8%);
      color: $color-text;
    }
    .custom{
      color: #2962FF;
      background: linear-gradient(180deg, #D9DDE4 0%, #EFF1F4 106.8%);
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
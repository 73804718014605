.modal {
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  .close{
    font-size: 25px;
    cursor: pointer;
    font-weight: 500;
    display: block;
    position: absolute;
    top: -5px;
    right: 5px;
    padding: 20px;
  }
}
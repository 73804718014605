@import '../../scss/variable.scss';

.sidebar{
  width: 240px;
  background: $background-secondary;
  color: $color-text;
  .logo{
    width: 240px;
    background: $background-gradient-main;
  }
  .menu {
    padding: 20px 0px 0px;
    font-size: 14.39px;
    letter-spacing: 1.74px;
    a{
      text-decoration:none;
      color: $color-text;
    }
    .item{
      text-align: left;
      padding: 10px 20px;
      transition: all 0.8s;

      &:hover {
        background: rgba($color-text, 0.1);
      }
    }
    .selected{
      color: $color-secondary;
      background: $color-text;
      font-weight: 700;
      border-right: 3px solid $color-secondary;
      transition: all 1s;
      &:hover {
        background: $color-text;
      }
    }
  }

}
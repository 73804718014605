@import '../../../scss/variable.scss';

.select-plan-modal{
  width: 500px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .content-select-plan{
    margin-top: 15px;
    margin-right: 10px;
    .row {
      margin-bottom: 15px;
      .label{
        font-weight: 500;
        font-size: 12px;
        letter-spacing: 2.42px;
        color: $color-secondary;
        margin-bottom: 10px;
      }
    }
    .plan-option {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 5px;
      input{
        margin-right: 30px;
        margin-left: 20px
      };
      [type='radio']:checked + label:before, [type='radio']:not(:checked) + label:before {
        top: 42px;
      }
      [type='radio']:checked + label:after, [type='radio']:not(:checked) + label:after{
        top: 46px;
      }
      svg{
        width: 400px;

      }
    }
    .content-buttons{
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-bottom: 20px;
      button{
        margin-left: 20px;
      }
    }
    > button{
      width: 50%;
      margin: 0px 25%;
    }
  }
}

.branding-palette-modal{
  width: 500px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .text-description{
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 2.42px;
    margin-top: 10px;
  }
  .content-tabs-colors{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    div{
      background: #424548;
      opacity: 0.6;
      border-radius: 3.2805px;
      font-size: 10px;
      color: #CCCCCC;
      padding: 5px 10px;
      text-transform: uppercase;
    }
    .tab-selected{
      background: $background-highlighting;
      color: $color-text;
      opacity: 1;
    }
  }
  .content-color-picker{
    height: 220px;
    margin: 20px 0px;
  }
  .content-buttons{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    button{
      width: 48%;
    }
  }
}

.branding-logo-modal{
  width: 500px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .content-modal{
    margin-top: 10px;
    .text-description{
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      letter-spacing: 2.42px;
    }
    .content-image-crop{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 30px;
      margin-bottom: 20px;
      .input-file{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
      .input-file+label {
        cursor: pointer;
        outline: none!important;
        box-shadow: none!important;
        outline-style: none!important;
        background-color: #ffffff;
        color: $color-secondary;
        border-radius: 2.55px;
        font-size: 12px;
        font-family: "Neue Haas Grotesk Display Pro",sans-serif;
        letter-spacing: 1.37px;
        font-weight: 500;
        width: auto!important;
        height: auto!important;
        padding: 6px;
        margin: 0 4px 4px;
      }
      .preview-content{
        text-align: center;
        > div:nth-child(1){
          font-weight: 700;
          font-size: 15px;
          line-height: 19px;
          text-align: center;
          letter-spacing: 2.42px;
          text-transform: uppercase;
          color: $color-secondary;
        }
      }
      .logo-preview-container {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        width: 340px;
        text-align: center;
        border: 1px dashed #fff;
        height: 220px;
        justify-content: center;
        align-items: center;
        img {
          max-width: 340px !important;
          height: 210px;
        }
      }
    }

    .content-buttons{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 20px;
      button{
        width: 48%;
      }
    }
    
  }
}

.user-modal{
  width: 373px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .content-modal{
    height: calc(95vh - 100px);
    max-height: 620px;
    .form-company{
      width: calc(100% - 15px);
      margin-right: 10px;
      margin-top: 15px;
      .row{
        width: 100%;
        margin-bottom: 15px;
        .label{
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 2.42px;
          color: $color-secondary;
        }
        input{
          width: 100%;
        }
        .dropdownContainer{
          margin-top: 10px;
        }
        .content-preview{
          display: flex;
          margin-top: 15px;
          margin-bottom: 20px;
        }
        .preview{
          width: 80px;
          height: 80px;
          margin-right: 30px;
          background: #2A2D31;
          border: 1px dashed #FFFFFF;
          img{
            width: 154px;
            height: 71px;
          }
        }
        .content-ratio-options{
          margin-top: 20px ;
          display: flex;
          > div {
            margin-right: 20px;
          }
        }
      }
      > button{
        margin-left: calc((100% - 190px) /2 );
      }
    }
    .content-button-password{
      margin-top: -27px;
      margin-left: calc(100% - 140px);
      button{
        margin-left: 10px;
        font-size: 10px;
        height: 22px;
      }
    }
  }
}

.message-modal{
  width: 380px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .text-description{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 2.42px;
    margin-top: 15px;
  }
  .content-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    button{
      margin-left: 10px;
    }
  }
}

@import '../../scss/variable.scss';

.login{
  background-color: $background-main;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-text;

  .content-login {
    height: 570px;
    width: 555px;
    border: 1px solid #fff;
    margin: auto;
    padding: 54px 68px 46px 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 10px;
    .logo {
      width: 100px;
      height: 100px;
    }
    .form{
      display: flex;
      flex-direction: column;
      letter-spacing: 1.54px;
      flex: 1 1;
      width: 100%;
      justify-content: space-between;
      margin: 30px 0;
      font-size: 13.5px!important;
      font-family: "Neue Haas Grotesk Display Pro",sans-serif;
      input {
        width: 100%;
        height: 45px;
        letter-spacing: 1.54px;
        color: #fff;
        background-color: transparent!important;
        border: none;
        border-bottom: 1px solid #c7c7c7;
        padding-left: 0!important;
      }
      .button{
        color: #fff;
        height: 32px;
        width: 124px;
        letter-spacing: 1.37px;
        border-radius: 4px;
        font-size: 12px;
        background: linear-gradient(270deg,#2962ff,#0039cb);
        font-weight: 700;
        border:none;
        &:hover {
          background: $button-primary-hover;
        }
        &:disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
      .row-password{
        position: relative;
      }
      .forgot{
        position: absolute;
        right: 3px;
        top: 12px;
        font-weight: 300;
      }
    }
    .separator{
      width: 82px;
      border-top: 1px solid #fff;
    }
    .information{
      width: 355px;
      text-align: center;
      margin-top: 28px;
      letter-spacing: 1.2px;
      font-size: 12px;
      font-family: "Neue Haas Grotesk Display Pro",sans-serif;
    }
    .link{
      line-height: 23px;
      color: #768fff;
      font-weight: 600;
    }

  }
  
}
// background color
$background-main: #1d1d1d;
$background-secondary: #29292a;
$background-gradient-main: linear-gradient(270deg, rgb(41, 98, 255), rgb(38, 39, 107));

//background widgets
$background-widget: #3b3f44;
$background-subwidget: #575a5f;

//Background highlighting
$background-highlighting: #2962ff;
$background-gradient-highlighting: linear-gradient(270deg, #2962ff 0%, #4aa9db 100%);

//backgroun card
$background-card: linear-gradient(270deg, rgb(41, 98, 255), rgb(38, 39, 107));

//Shadow
$shadow-main: rgba(0, 0, 0, 0.2) 0px 0px 16px;

//Background color buttons
$button-primary: linear-gradient(270deg, #2962ff 0%, #0039cb 100%);
$button-primary-hover: linear-gradient(270deg, #768fff 0%, #2962ff 100%);
$button-secondary: #ffffff;
$button-secondary-hover: linear-gradient(90deg,#ffffff 0%,#d5d5d5 97%,#d4d4d4 100%);

//text 
$color-text: #ffffff;
$color-secondary: #2962ff;

//components

$background-select: #2a2d31;
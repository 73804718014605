@import '../../scss/variable.scss';

.Main {
  display: flex;
  .principal-content {
    width: calc(100% - 240px);
    background-color: $background-main;
    min-height: 100vh;
    color: $color-text;
    .content-pages{
      padding: 18px 18px 0px 18px
    }
  }
}
  

@import '../../scss/variable.scss';

.home-page{
  .content{
    background-color: $background-secondary;
    border-radius: 10px;
    padding: 18px;
    margin-bottom: 10px;
    text-align: left;
  }
  .home-title{
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 2.58px;
    line-height: 24px;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 0.5px solid #ffffff66;
    margin-bottom: 10px;
  }
  .description{
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
  }
  .content-card{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 30px;
    .card{
      width: calc(33% - 40px);
      height:150px;
      margin-right: 20px;

    }
  }
  .maintenance-mode{
    display: flex;
    .title{
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 2.58px;
      text-transform: uppercase;
    }
    div {
      margin-right: 5px;
    }
  }
}

@media (max-width: 1100px) {
  .home-page{
    .content-card{
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      .card{
        width: 40% !important;
      }
    }
  }
}
@import '../../scss/variable.scss';

.plan-management-page{
  color: $color-text;
  background-color: $background-secondary;
  border-radius: 10px;
  padding: 18px;
  margin-bottom: 10px;
  text-align: left;
  .content-widget{
    background-color: $background-widget;
    border-radius: 10px;
    padding: 18px;
    text-align: left;
    .sub-widget{
      background-color:$background-subwidget;
      border-radius: 10px;
      padding: 18px;
      text-align: left;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      > div{
        margin-right: 10px;
      }
    }
    .subtitle{
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-align: justify;
      letter-spacing: 2.42308px;
      text-transform: uppercase;
    }

    .content-list{
      padding: 5px 0px;
      .header-list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .title{
          color: $color-text;
          font-size: 15px;
          font-weight: 500;
          display: flex;
          align-items: center;
          svg {
            margin-right: 10px;
          }
        }
      }
      .content-table{
        margin-top: 10px;
        height: 400px;
      }
      table{
        width: calc(100% - 15px);
        border-collapse: collapse;
        thead{
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          tr th{
            padding-bottom: 5px;
            padding-left: 10px;
            svg{
              margin-left: 5px;
            }
          }
          .header-button-delete{
            width: 100px;
          }
          .header-button-status{
            width: 100px;
          }
        }
        tbody{
          background: #2A2D31;
          font-size: 12px;
          tr{
            border-bottom: 1px solid #FFFFFF;
            td{
              padding: 10px;
              .table-buttons{
                display: flex;
              }
              button{
                margin-left: 10px;
                width: 100px;
              }
            }
          }
        }
      }
    }

    .content-title{
      display: flex;
      justify-content: space-between;
      >div{
        font-weight: 700;
        font-size: 15px;
        line-height: 19px;
        /* identical to box height */
        text-align: justify;
        letter-spacing: 2.42308px;
        text-transform: uppercase;
      }
      button{
        width: 70px;
        height: 22px;
        margin-left: 10px;
        font-size: 10px;
      }
    }

    .plan-button{
      width: 150px;
      padding: 2px;
      text-align: center;
      border-radius: 31px;
      background: linear-gradient(180deg, #D9DDE4 0%, #EFF1F4 106.8%);
      color: #3B3F44;
    }
    .trial{
      background: linear-gradient(180deg, #FA5400 0%, #FE6D24 0.01%, #FF2929 100%);
      color: $color-text;
    }
    .core{
      background: linear-gradient(180deg, #245DF8 0%, #0039CB 106.8%);
      color: $color-text;
    }
    .premium{
      background: linear-gradient(180deg, #4B4F56 0%, #313336 106.8%);
      color: $color-text;
    }
    .custom{
      color: #2962FF;
      background: linear-gradient(180deg, #D9DDE4 0%, #EFF1F4 106.8%);
    }

    .form-company{
      width: 100%;
      margin-top: 20px;
      .row{
        width: 100%;
        margin-bottom: 15px;
        .label{
          font-weight: 500;
          font-size: 16px;
          letter-spacing: 2.42px;
          color: $color-secondary;
        }
        input{
          width: 100%;
          font-weight: 400;
    
        }
        .item-switch{
          display: flex;
          align-items: center;
          .item{
            background: #2962FF;
            border-radius: 4.05px;
            padding: 10px 23px;
            font-weight: 700;
            font-size: 13px;
            margin-right: 26px;
          }
          .input-amount{
            background: #2A2D31;
            border: none;
            width: 60px;
            margin-left: 10px;
            font-size: 16px;
            padding: 6px;
          }
        }
      }
    }
    
    .content-buttons{
      display: flex;
      margin-left: calc(100% - 160px);
      .button-apply{
        min-width: 70px;
        margin-left: 10px;
        height: 22px;
        font-size: 10px;
      }
    }
  }
  .title-plan-management{
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    text-align: justify;
    letter-spacing: 2.42308px;
    text-transform: uppercase;
    margin: 10px 0px;
    display: flex;
    align-items: center;
    svg{
      margin-right: 10px;
    }
  }
}

.message-modal{
  width: 380px;
  .header{
    font-size: 18px;
    padding: 10px 0px;
    border-bottom: 0.5px solid #FFFFFF;
    display: flex;
    align-items: flex-end;
    line-height: 23px;
    letter-spacing: 2.97231px;
    svg{
      margin-right: 10px;
    }
  }
  .text-description{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 2.42px;
    margin-top: 15px;
  }
  .content-buttons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 25px;
    button{
      margin-left: 10px;
    }
  }
}
